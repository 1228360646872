/* body{
    background-image: url('./images/3.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
} */
@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@200&family=Rye&display=swap');

.flixed_name{
    font-family: 'Rye', cursive;
    color: #E85A4F;
}

.flixed_app{
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    font-family: 'Kanit', sans-serif;
    color: #8e8d8a;
    background-color: #EAE7DC;
}

.flixed_navLink:hover{
    color:black;
    text-decoration:none;
    cursor: pointer;
}

.flixed_navLink{
    color:inherit;
    text-decoration:none;
}

.flixed_footer{
    margin-top: auto;
    display: flex;
    /* height: 80px; */
    justify-content: space-evenly;
    align-items: center;
    background-color: #EAE7DC;
    /* padding-top: 10px; */
}

.flixed_main{
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    flex-direction: column;
}

.working{
    height: auto;
    max-width: 100%;
}